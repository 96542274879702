import { useDispatch } from 'react-redux';
import ApproveTx from './ApproveTx';
import RejectTx from './RejectTx';
import { formatNetwork } from '../../../../utils/networkTools';
import { currentComponent, showModal } from '../../../../store/modal';
import { toast } from 'react-hot-toast';
const TxActions = ({ tx, canApprove }) => {
  const dispatch = useDispatch();

  const displayApprove = () => {
    if (!canApprove) {
      return toast.error('Unauthorized.');
    }
    dispatch(
      currentComponent(() => (
        <ApproveTx
          id={tx.txId}
          owner={tx.ownerId}
          amt={`${tx.amount} ${formatNetwork(tx.network)}`}
        />
      )),
    );
    dispatch(showModal());
  };

  const displayReject = () => {
    dispatch(currentComponent(() => <RejectTx id={tx.txId} />));
    dispatch(showModal());
  };

  if (tx.approval.status !== 'pending') {
    return null;
  }

  return (
    <div className="flex justify-evenly space-x-10">
      <button
        onClick={displayApprove}
        disabled={!canApprove}
        className={`bg-gray-200 bg-opacity-10 p-3 flex-1 text-center md:text-lg rounded cursor-pointer border border-transparent ${canApprove ? 'hover:border-green-500' : 'opacity-50'}`}
      >
        Approve
      </button>
      <button
        onClick={displayReject}
        className="bg-gray-200 bg-opacity-10 p-3 flex-1 text-center md:text-lg rounded cursor-pointer border border-transparent hover:border-red-500"
      >
        Reject
      </button>
    </div>
  );
};

export default TxActions;
